import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const BEZIER = 'cubic-bezier(0.820, 0.000, 0.275, 1.005)';

const HORIZONTAL_ROLLS = new Set(['left', 'right']);
const NEGATIVE_TRANSLATES = new Set(['top', 'left']);

function RollIn(props) {
  const { children, color, transitionProps, show, direction } = props;

  const translate = React.useMemo(
    () => (HORIZONTAL_ROLLS.has(direction) ? 'translateX' : 'translateY'),
    [direction]
  );
  const sign = React.useMemo(
    () => (NEGATIVE_TRANSLATES.has(direction) ? -1 : 1),
    [direction]
  );

  function renderContent() {
    if (children != null) {
      return children;
    }
    if (color != null) {
      return (
        <div css={{ width: '100%', height: '100%', backgroundColor: color }} />
      );
    }
    return null;
  }

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden',
      }}
    >
      <div
        css={{
          width: '100%',
          height: '100%',
          transform: `${translate}(${sign * (show ? 0 : 100)}%)`,
          transition: '1s',
          transitionTimingFunction: BEZIER,
          overflow: 'hidden',
          ...transitionProps,
        }}
      >
        <div
          css={{
            width: '100%',
            height: '100%',
            transform: `${translate}(${sign * -1 * (show ? 0 : 70)}%)`,
            transition: '1s',
            transitionTimingFunction: BEZIER,
            ...transitionProps,
          }}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default RollIn;
